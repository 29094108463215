import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'

import { VisbilityContainer, CommonPose, LinkPose } from '../../home/Animations'
import { Link as ChaliceLink } from '../button/Button'
import { PAGEURL } from '../../../constants'
import { ChaliceImg } from './base'

const SmartButton = ({ href, text, ...rest }) => {
  // Does this link contain a protocol?
  if (href?.match('://')) {
    return (
      <ExploreButton href={href || '#'} {...rest}>
        {text}
      </ExploreButton>
    )
  } else {
    return (
      <Link href={href || '#'} passHref>
        <ExploreButton {...rest}>{text}</ExploreButton>
      </Link>
    )
  }
}

export const MainExplore = inject('store')(
  observer(({ store, url, text }) => (
    <SmartButton
      href={
        url || store.siteData.get('home_explore_url') || PAGEURL.MARKETPLACE
      }
      text={
        text || store.siteData.get('home_explore_text') || 'Explore Marketplace'
      }
    />
  ))
)

export const MainCta = inject('store')(
  observer(({ store, cta, hasCta, isPreview, ...rest }) => {
    const { siteData } = store
    let content = null
    if (cta === false || !hasCta) {
      return null
    } else if (cta) {
      content = (
        <SmartButton
          href={isPreview ? '#' : cta?.url}
          text={cta?.text}
          {...rest}
        />
      )
    } else if (siteData.get('allow_signup') && !store.isAuthenticated()) {
      const url = siteData.get('signup_url') || PAGEURL.SIGNUP
      content = (
        <SmartButton
          href={isPreview ? '#' : url}
          text={siteData.get('signup_text') || 'Signup'}
          {...rest}
        />
      )
    } else {
      content = <MainExplore isPreview={isPreview} {...rest} />
    }
    return <CommonPose className="action-row">{content}</CommonPose>
  })
)

MainCta.propTypes = {
  store: PropTypes.object
}

export const ExploreButton = styled(ChaliceLink)`
  background-color: ${(props) => props.theme.colors.primary};
`

const Wide = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    transition-delay: 500ms;
  }
`

export const WideImage = ({ imageUrl }) => (
  <Wide>
    <ChaliceImg src={imageUrl} />
  </Wide>
)

WideImage.propTypes = {
  imageUrl: PropTypes.any
}

export const Underline = styled.span`
  text-decoration: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: ${(props) => props.bottom || '10px'};
    width: 100%;
    height: ${(props) => props.height || '10px'};
    background: ${(props) => props.theme.colors.primary};
    opacity: 0.7;
  }
`
